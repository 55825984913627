<template>
  <footer class="app-footer">
    <div class="group">
      <accordeon
        v-for="(menu, index) in accordeonItems"
        :key="index"
        :heading="menu?.title"
        :items="menu?.items"
        :is-open="true"
        mode="toggle"
      >
        <template #header="{ heading }">
          <h4>{{ heading }}</h4>
        </template>

        <template #content="{ key, item }: { key: number; item: ILink }">
          <atomic-link :href="item.url" :target-blank="item?.targetBlank">
            {{ item.label }}
          </atomic-link>

          <div
            v-if="index === accordeonItems.length - 1 && key === menu.items.length - 1 && footerSupportEmail"
            class="app-footer__support"
          >
            <span class="app-footer__support-title">
              {{ getContent(layoutData, defaultLocaleLayoutData, 'footer.support.title') }}
            </span>

            <a class="app-footer__support-link" :href="`mailto:${footerSupportEmail}`">
              {{ footerSupportEmail }}
            </a>
          </div>
        </template>
      </accordeon>

      <partners
        v-if="layoutData?.footer?.partners?.isShow"
        :label="getContent(layoutData, defaultLocaleLayoutData, 'footer.partners.label')"
        :items="getContent(layoutData, defaultLocaleLayoutData, 'footer.partners.items')"
      />

      <div class="app-footer__connections">
        <div
          v-if="getContent(layoutData, defaultLocaleLayoutData, 'footer.affiliate.link')"
          class="app-footer__affiliate"
        >
          <div class="app-footer__affiliate-title">
            {{ getContent(layoutData, defaultLocaleLayoutData, 'footer.affiliate.title') }}
          </div>

          <atomic-link
            class="app-footer__affiliate-link"
            :href="getContent(layoutData, defaultLocaleLayoutData, 'footer.affiliate.link')"
            :targetBlank="getContent(layoutData, defaultLocaleLayoutData, 'footer.affiliate.targetBlank')"
          >
            <atomic-image :src="getContent(layoutData, defaultLocaleLayoutData, 'footer.affiliate.logo')" />
          </atomic-link>
        </div>

        <div v-if="footerSocials.length" class="app-footer__socials">
          <div class="app-footer__socials-title">
            {{ getContent(layoutData, defaultLocaleLayoutData, 'footer.socials.title') }}
          </div>

          <div class="app-footer__socials-list">
            <atomic-link
              v-for="(item, index) in footerSocials"
              :key="index"
              class="app-footer__socials-item"
              :href="item.url"
              :target-blank="item?.targetBlank"
              :rel="item.nofollow ? 'nofollow' : null"
            >
              <atomic-icon :id="item.icon" />
            </atomic-link>
          </div>
        </div>
      </div>
    </div>

    <atomic-divider />

    <client-only>
      <list-paysis />
    </client-only>

    <atomic-divider />

    <list-certificates />

    <atomic-divider />

    <template v-if="layoutData?.footer?.custom?.show || defaultLocaleLayoutData?.footer?.custom?.show">
      <div class="info">
        <atomic-link
          v-if="customLicenseImage && customLicenseLink"
          class="info__image-link"
          :href="customLicenseLink"
          target-blank
        >
          <atomic-image :src="customLicenseImage" data-not-lazy />
        </atomic-link>

        <div v-else-if="customLicenseImage" class="info__image">
          <atomic-image :src="customLicenseImage" data-not-lazy />
        </div>

        <div
          v-if="layoutData?.footer?.custom?.description || defaultLocaleLayoutData?.footer?.custom?.description"
          class="info__text"
          v-html="customLicenceDescription"
        />
      </div>

      <atomic-divider />
    </template>

    <template v-else-if="layoutData?.footer?.curacao?.show || defaultLocaleLayoutData?.footer?.curacao?.show">
      <div class="info">
        <iframe v-not-lazy
          v-if="layoutData?.footer?.curacao?.frameLink || defaultLocaleLayoutData?.footer?.curacao?.frameLink"
          :src="layoutData?.footer?.curacao?.frameLink || defaultLocaleLayoutData?.footer?.curacao?.frameLink"
          width="132px"
          height="62px"
          data-not-lazy
        />

        <div
          v-if="layoutData?.footer?.curacao?.description || defaultLocaleLayoutData?.footer?.curacao?.description"
          class="info__text"
          v-html="licenceDescription"
        />
      </div>

      <atomic-divider />
    </template>

    <div class="copy-info">
      <p>{{ layoutData?.footer?.copyright || defaultLocaleLayoutData?.footer?.copyright }}</p>
    </div>
  </footer>
</template>

<script setup lang="ts">
  import { marked } from 'marked';
  import type { ILink } from '~/types';
  import DOMPurify from 'isomorphic-dompurify';

  const { getContent } = useProjectMethods();
  const { layoutData, defaultLocaleLayoutData } = useGlobalStore();
  const accordeonItems = [
    getContent(layoutData, defaultLocaleLayoutData, 'footer.promoMenu'),
    getContent(layoutData, defaultLocaleLayoutData, 'footer.infoMenu'),
    getContent(layoutData, defaultLocaleLayoutData, 'footer.helpMenu'),
  ].filter(menu => menu?.title && menu?.items?.length);

  const customLicenseImage = computed(() => getContent(layoutData, defaultLocaleLayoutData, 'footer.custom.image'));
  const customLicenseLink = computed(() => getContent(layoutData, defaultLocaleLayoutData, 'footer.custom.link'));

  const licenceDescription = computed(() => {
    const descriptionContent = getContent(layoutData, defaultLocaleLayoutData, 'footer.curacao.description');
    if (!descriptionContent) return '';
    return DOMPurify.sanitize(marked.parse(descriptionContent) as string, { FORBID_TAGS: ['style'] });
  });

  const customLicenceDescription = computed(() => {
    const descriptionContent = getContent(layoutData, defaultLocaleLayoutData, 'footer.custom.description');
    if (!descriptionContent) return '';
    return DOMPurify.sanitize(marked.parse(descriptionContent) as string, { FORBID_TAGS: ['style'] });
  });

  const footerSupportEmail = getContent(layoutData, defaultLocaleLayoutData, 'footer.support.email');
  const footerSocials = getContent(layoutData, defaultLocaleLayoutData, 'footer.socials.items') || [];
</script>

<style src="~/assets/styles/components/layout/footer.scss" lang="scss" />
