<template>
  <div v-if="certificateItems.length" class="certificates">
    <div class="certificates__title">
      {{ getContent(layoutData, defaultLocaleLayoutData, 'footer.certificates.title') }}
    </div>

    <div class="certificates__container">
      <atomic-link
        v-for="(item, index) in certificateItems"
        :key="index"
        class="certificates__item"
        :href="item.url"
        :target-blank="item?.targetBlank"
        :rel="item.nofollow ? 'nofollow' : null"
      >
        <atomic-image :src="item.image" :alt="item.alt" />
      </atomic-link>
    </div>
  </div>
</template>

<script setup lang="ts">
  const { layoutData, defaultLocaleLayoutData } = useGlobalStore();
  const { getContent } = useProjectMethods();

  const certificateItems = computed(() => {
    return getContent(layoutData, defaultLocaleLayoutData, 'footer.certificates.list') || [];
  });
</script>

<style src="~/assets/styles/components/list/certificates.scss" lang="scss" />
