<template>
  <div class="list-balance" :class="{ 'is-show': props.isOpen }">
    <div class="list-balance__items">
      <div class="list-balance__item">
        <atomic-icon id="bonus" class="list-balance__icon" />

        <span class="list-balance__title">
          {{ getContent(layoutData, defaultLocaleLayoutData, 'header.balance.items.bonus') }}
        </span>

        <span class="list-balance__value">
          {{ activeAccountBalances.bonus.balance }} {{ activeAccountBalances.bonus.currency }}
        </span>

        <div v-if="props.isOpen && activePlayerBonuses.length" class="list-balance__bonus-progress">
          <bonuses-wager-progress :bonus-info="activePlayerBonuses[0]" />
        </div>
      </div>

      <div class="list-balance__item">
        <atomic-icon id="wallet" class="list-balance__icon" />

        <div class="list-balance__title">
          {{ getContent(layoutData, defaultLocaleLayoutData, 'header.balance.items.real') }}
        </div>

        <span class="list-balance__value">
          {{ activeAccountBalances.real.balance }} {{ activeAccountBalances.real.currency }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';

  const props = defineProps({
    isOpen: {
      type: Boolean,
      default: false,
    },
  });

  const walletStore = useWalletStore();
  const globalStore = useGlobalStore();
  const { activeAccount } = storeToRefs(walletStore);
  const { layoutData, defaultLocaleLayoutData } = storeToRefs(globalStore);
  const { getContent } = useProjectMethods();

  const bonusStore = useBonusStore();
  const { activePlayerBonuses } = storeToRefs(bonusStore);

  interface IBalance {
    balance: number;
    currency: string;
    currencySymbol?: string;
    currencyIcon?: string;
    date?: string;
  }

  const activeAccountBalances = computed<{ real: IBalance; bonus: IBalance }>(() => {
    const real = {
      balance: (activeAccount.value?.realBalance || 0) + (activeAccount.value?.lockedBalance || 0),
      currency: activeAccount.value?.currency || '',
    };
    const bonus = { balance: activeAccount.value?.bonusBalance || 0, currency: activeAccount.value?.currency || '' };

    return { real, bonus };
  });
</script>

<style src="~/assets/styles/components/list/balance.scss" lang="scss" />
